// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import MobileStoreButton from '@components/MobileStoreButton'
import css from "@css/components/common/fullPageSection.module.styl"
import heroCss from "@css/components/index/hero.module.styl"

const iosUrl = 'https://sugarlive.page.link/corp'
const androidUrl = 'https://sugarlive.page.link/corp-android'

const Hero = () => (
	<section className={css.container}>
		<div className={css.wrap}>
			<div className={heroCss.container}>
				<p className={heroCss.subTitle}>LIVE stream on SUGAR</p>
				<h1 className={heroCss.title}>
					<span>Bringing&nbsp;</span><span>wonder</span><br/>
					<span>before&nbsp;</span><span>your&nbsp;</span><span>eyes</span>
				</h1>
				<h2 className={heroCss.copy}>
					<span>SUGARで</span><span>あこがれのあの人と</span><br/>
					<span>話せる、</span><span>つながる。</span>
				</h2>
				<div className={heroCss.storeLinks}>
					<MobileStoreButton
						store="ios"
						url={iosUrl}
						linkProps={{ title: 'App Store' }}
					/>
					<MobileStoreButton
						store="android"
						url={androidUrl}
						linkProps={{ title: 'Google Play' }}
					/>
				</div>
			</div>
		</div>
	</section>
)

// Header.propTypes = {
// 	siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
// 	siteTitle: ``,
// }

export default Hero