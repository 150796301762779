import React from "react"
import ReactFullpage from '@fullpage/react-fullpage'

const licenseKey = '2C6FE84E-E7C14757-AA826302-E3B1FC7B'

// export default class FullPage extends React.Component
// {
//     licenseKey = '2C6FE84E-E7C14757-AA826302-E3B1FC7B'
//     constructor(props) {
//         super(props)
//     }

//     /*
//      *    レンダー
//      */
//     render(){
//         const { children, render, anchors, onLeave } = this.props
//         return(
//         	<ReactFullpage
//                 anchors={ anchors ? anchors : []}
//                 sectionsColor={[]}//what???
//                 licenseKey={this.licenseKey}
//                 render={render}
//                 onLeave={onLeave ? onLeave : ()=>{}}
//             />
//         )
//     }
// }

export default ({ children, render, anchors, onLeave, colors, isModal }) => {
    // if( typeof window !== 'undefined'){
        return (
            <ReactFullpage
                anchors={ anchors ? anchors : []}
                sectionsColor={colors ? colors : []}//what???
                licenseKey={licenseKey}
                render={render}
                onLeave={onLeave ? onLeave : ()=>{}}
                scrollingSpeed = {1000}
                dragAndMove = {isModal ? false : true}
                sectionSelector = {'.section'}
            />
        )
    // }
    return (
        <div>
            {children}
        </div>
    )
}

export const Wrapper = ReactFullpage.Wrapper

export const Section = ({children}) => (
    <div className="section">
        {children}
    </div>
)