// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { inject, observer } from 'mobx-react'
import { TimelineMax, TweenMax, Power4} from 'gsap'
import { customEase } from 'src/utils'

import css from "assets/css/components/common/fullpageGide.module.styl"

@inject('store')
@observer
class FullPageGide extends React.PureComponent
{
    preSection = null
    currentSection = null
    state = {
        section: null
    }

    componentDidMount(){
        const {color, store} = this.props
        const { FullPageStore } = store
        let section = FullPageStore.currentSection ? FullPageStore.currentSection : '0'
        let direction = FullPageStore.direction

        this.slideAnimation({ section, direction })
    }

    constructor(props) {
        super(props)
        this.dotsRef = React.createRef()
    }

    slideAnimation({ section, direction}){

        const { preColor } = this
        const { color, anchors} = this.props

        if(direction === "up"){
        } else if(direction === "down"){
        }
        // console.log(this.dotsRef)
    }

    render(){
        const {colors, section, store} = this.props
        const { FullPageStore } = store
        let direction = FullPageStore.direction
        let color = colors[section]
        // console.log('>>>>',this.preSection, section)

        // if( this.preSection !== section ){
        //     this.animation( section, direction )
        //     this.preSection = section
        //     this.preColor = color
        // }

        // if( this.topRef.current && this.bottomRef.current && direction){
        // }
        if( !this.preSection ){
            this.preSection = section
            this.currentSection = section
        }
        // console.log('------------>',direction, section)
        if( this.preSection !== section){
            if( direction ){
                this.slideAnimation({ section, direction })
            }
        }

        let list = []
        for(let i in colors){
            list.push(<li className={`${css.dot} ${i == section ? css.dotCurrent : null}`} key={i} style={{background: colors[section]}}/>)
        }

        return(
            <div className={css.container}>
                <div className={css.gide}>
                    <ul className={css.dots} ref={this.dotsRef}>
                        {list}
                    </ul>
                </div>
            </div>
        )
    }
}


export default FullPageGide