import React from "react"
import { inject, observer } from 'mobx-react'
import { TweenMax } from 'gsap'

import css from "assets/css/components/index/background.module.styl"

@inject('store')
@observer
class Background extends React.PureComponent
{
    preSection = 'top'
    constructor(props) {
        super(props)
        this.currentRef = React.createRef()
        this.nextRef = React.createRef()
    }
    render(){
        const { data, store } = this.props
        const { FullPageStore } = store
        const { currentSection } = FullPageStore
        const nextDom = this.nextRef.current
        const currentDom = this.currentRef.current

        if( currentSection !== this.preSection && nextDom && currentDom){

            nextDom.classList.remove(`${this.preSection}-bg`)
            nextDom.classList.add(`${currentSection}-bg`)

            TweenMax.to(nextDom,0.6,{
                opacity: 1,
                onComplete: ()=>{
                    currentDom.classList.remove(`${this.preSection}-bg`)
                    currentDom.classList.add(`${currentSection}-bg`)
                    TweenMax.set(nextDom,{
                        opacity: 0,
                    })
                    this.preSection = currentSection
                }
            })
        }

        return(
            <div className={css.container}>
                <div ref={this.currentRef} className={`${css.current} top-bg`}/>
                <div ref={this.nextRef} style={{opacity: 0}} className={`${css.next} top-bg`}/>
            </div>
        )
    }
}

export default Background
