// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'
// import TransLink from "@components/MyTransitionLink"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import css from "assets/css/components/common/fullPageSection.module.styl"

const iosUrl = '#'
const androidUrl = '#'

const About = ({color}) => (
	<section className={css.container} style={{color: color}}>
        <div className={css.wrap}>
            <p className={css.index}>ABOUT</p>
            <h2 className={css.title}>
                <span>おどろきを、</span><span>つねに。</span><br/>
                <span>ライブ配信アプリ</span><span className={css.textSugar}>「SUGAR」</span>
            </h2>
            <div className={css.links}>
                <Link to='/about' style={{background: color}}>View</Link>
            </div>
        </div>
	</section>
)

// Header.propTypes = {
// 	siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
// 	siteTitle: ``,
// }

export default About