import React from "react"
// import { TransitionState, TransitionPortal } from "gatsby-plugin-transition-link"
import { inject, observer } from 'mobx-react'
import { graphql } from "gatsby"

import pMinDelay  from 'p-min-delay'
import loadable from '@loadable/component'
// import PageTransition from 'gatsby-plugin-page-transitions'

// import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'

import BackgroundImage from 'gatsby-background-image'

import FullPage, { Wrapper, Section } from '@components/FullPage'
import FullPageGide from '@components/FullPageGide'
import { TimelineMax, TweenMax } from 'gsap'

import Layout from "@layouts/index"
import SEO from "@components/seo"
// import Header from '@components/Header'
import Hero from '@components/index/Hero'
import About from '@components/index/About'
import Recruit from '@components/index/Recruit'
import News from '@components/index/News'
import Background from '@components/index/Background'

import css from "@css/components/index/index.module.styl"


@inject('store')
@observer
class IndexPage extends React.Component
{

    anchors = ['top', 'about' , 'recruit','news']
    colors = [
        '#007BFC',//'#00A7FC',
        '#FF5000',
        '#4B00FF',
        '#1A1A3D'
    ]

    state = {
        isThreeLoaded: false,
        isMobile: false
    }
    isShowMain = true

    constructor(props) {
        super(props)
        this.bgRef = React.createRef()
        this.mainRef = React.createRef()
        // this.mobxShouldComponentUpdate = this.shouldComponentUpdate
        // this.shouldComponentUpdate = this.customShouldComponentUpdate
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize, false)
        this.onResize()
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize, false)
    }

    saveCurrentSection(current,direction){
        const { store } = this.props
        const { FullPageStore } = store
        if( FullPageStore.direction !== direction ){
            FullPageStore.direction = direction
        }
        if(FullPageStore.currentSection !== current ){
            FullPageStore.currentSection = current
        }
    }
    /*
     *    トリガー
     */
    fullPageOnReave = (origin, destination, direction)=>{
        const { store } = this.props
        // const { FullPageStore } = store
        // this.bgRef.current.classList.remove(`${FullPageStore.currentSection}-bg`)
        // this.bgRef.current.classList.add(`${destination.anchor}-bg`)
        // this.saveCurrentSection(destination.anchor, direction)
        this.saveCurrentSection(destination.index, direction)
    }

    dynamicThreeJs = loadable(() => {
        return pMinDelay(import('@components/lib/ThreeJs'), 100).then((value)=>{
            this.setState({isThreeLoaded: true})
            return value
        })
    })

    onResize = ()=>{
        let w = window.innerWidth
        if(w <= 768){
            this.setState({isMobile: true})
        } else {
            this.setState({isMobile: false})
        }
    }

    hideMain = () => {
        let t = this.mainRef.current

        if(this.isShowMain == false){ return }
        this.isShowMain = false

        TweenMax.to(t, 0.6, {
            opacity: 0
        })
    }

    showMain = () => {
        let t = this.mainRef.current

        if(this.isShowMain == true){ return }
        this.isShowMain = true

        TweenMax.to(t, 0.6, {
            opacity: 1
        })
    }

    /*
     *    レンダリング
     */
    render(){
        const { data, store, location } = this.props
        // const { global__img__pattern } = data
        const { FullPageStore, UserAgentStore, LoadStore } = store
        let section = FullPageStore.currentSection ? FullPageStore.currentSection : 0
        let direction = FullPageStore.direction ? FullPageStore.direction : 'none'
        // console.log(global__img__pattern)
        // let moreLoads = [
        //     ()=>{ return LoadStore.threeJsLoaded }
        // ]
        if( UserAgentStore.isModal ){
            this.hideMain()
        } else {
            this.showMain()
        }


        return (
            <div>
        		<SEO title='home' keywords={[`key`, `wor`, `d`]} />
                {/*<Background/>*/}
                <this.dynamicThreeJs draco={data.draco} dracoDir={data.dracoDir} />
                <main className={css.main} ref={this.mainRef}>
                    <FullPageGide className={css.gide} colors={this.colors} section={section} direction={direction} anchors={this.anchors}/>
                    <FullPage
                        //anchors={this.anchors}
                        render={this.fullpageContent}
                        onLeave={this.fullPageOnReave}
                        isModal={UserAgentStore.isModal}
                    />
                </main>
            </div>
        )
    }

    fullpageContent = ({ state, fullpageApi}) => {
        const { data, store, location } = this.props
        const { FullPageStore, UserAgentStore } = store
        let section = FullPageStore.currentSection ? FullPageStore.currentSection : 0
        let color = this.colors[section]
        let colors = this.colors
        return (
            <Wrapper>
                <Section>
                    <Hero color={colors[0]}/>
                </Section>
                <Section>
                    <About color={colors[1]}/>
                </Section>
                {/*<Section>
                    <Recruit color={colors[2]}/>
                </Section>*/}
                <Section>
                    <News color={colors[3]} newsData={this.props.data.allNewsItemsJson}/>
                </Section>
            </Wrapper>
        )
    }
}

export default IndexPage

export const query = graphql`
    query {
        allNewsItemsJson {
            edges {
                node {
                    date
                    title
                    link
                    target
                }
            }
        }
        draco: allFile(filter: { sourceInstanceName: { eq: "draco" } }) {
            edges {
                node {
                    dir
                    publicURL
                    name
                    ext
                }
            }
        }

        dracoDir: allDirectory(filter: {name: {eq: "draco"}}) {
            edges {
                node {
                    absolutePath
                }
            }
        }
    }
`
