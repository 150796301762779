// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'
// import TransLink from "@components/MyTransitionLink"
// import TransLink from "@components/MyTransitionLink"

import css from "assets/css/components/common/fullPageSection.module.styl"
import newsCss from "@css/components/index/news.module.styl"


const makeList = (item) => {
    const { edges } = item
    let list = []
    // for(let i = 0; i < edges.length; i++ ){
    let max = edges.length > 3 ? 3 : edges.length
    for(let i = 0; i < max; i++ ){
        let row = edges[i].node
        if( row.link !== "" ){
            list.push(
                <li className={newsCss.item} key={i}>
                    <a className={newsCss.inner} href={row.link} target={row.target}>
                        <div className={newsCss.date}>{row.date}</div>
                        <div className={newsCss.title}>{row.title}</div>
                    </a>
                </li>
            )
        } else {
            list.push(
                <li className={newsCss.item} key={i}>
                    <div className={newsCss.inner}>
                        <div className={newsCss.date}>{row.date}</div>
                        <div className={newsCss.title}>{row.title}</div>
                    </div>
                </li>
            )
        }
    }
    return (
        <ul className={newsCss.list}>
            {list}
        </ul>
    )
}

const News = ({newsData, color}) => (
	<section className={css.container} style={{color: color}}>
        <div className={css.wrap}>
            <p className={css.index}>NEWS</p>
            <div className={newsCss.container}>
                {makeList(newsData)}
            </div>
            <div className={css.links}>
                <Link to='/news' direction='next' style={{background: color}}>View</Link>
            </div>
        </div>
	</section>
)

// Header.propTypes = {
// 	siteTitle: PropTypes.string,
// }s

// Header.defaultProps = {
// 	siteTitle: ``,
// }

export default News